import { WppAvatar, WppIconHistory, WppTag } from '@platform-ui-kit/components-library-react'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { useGetDownloadUrlsCoreApi } from 'api/attachments/queries/useGetDownloadUrlsCoreApi'
import { useGetUserById } from 'api/user/queries/useGetUserById'
import { useCollectionData } from 'hooks/useCollectionData'
import { API } from 'types/common/enums'
import { Flex } from 'ui-base/flex/Flex'
import { Truncate } from 'ui-base/truncate/Truncate'
import { Breadcrumbs } from 'ui-shared/breadcrumbs/Breadcrumbs'
import { CollectionStatusTag } from 'ui-shared/collectionStatusTag/CollectionStatusTag'
import dayjs from 'utils/date'

import * as S from 'pages/collections/common/collectionHeader/CollectionHeader.styled'

interface Props {
  breadcrumbLabel?: string
  renderRight?: ReactNode
}

export const CollectionHeader = ({ breadcrumbLabel, renderRight, ...rest }: Props) => {
  const { t } = useTranslation(['collections', 'common'])
  const { collection } = useCollectionData()
  const { data: creatorDetails, isLoading: isCreatorDetailsLoading } = useGetUserById({
    params: { userId: collection?.createdByEmail },
    enabled: !!collection?.createdByEmail,
  })
  const { data: creatorAvatarSrc } = useGetDownloadUrlsCoreApi({
    params: { api: API.CORE_FE_PROXY, keys: [creatorDetails?.avatarThumbnail?.key!] },
    enabled: !!creatorDetails?.avatarThumbnail?.key && !isCreatorDetailsLoading,
    staleTime: 180000, // 3min
  })

  return (
    <S.HeaderWrapper direction="column" gap={16} {...rest} data-testid="collection-header-container">
      <Breadcrumbs breadcrumbLabel={breadcrumbLabel} />

      <Flex justify="between" align="center" gap={24}>
        <S.CollectionNameTooltip
          show
          showOnOverflow
          text={collection!.name}
          getInnerElement={el => el?.shadowRoot?.querySelector('[part="typography"]')}
          config={{ placement: 'top' }}
        >
          <Truncate type="3xl-heading" tag="h1" data-testid="collection-header-name">
            {collection!.name}
          </Truncate>
        </S.CollectionNameTooltip>

        {renderRight}
      </Flex>

      <Flex align="center" gap={8}>
        <WppTag
          variant="neutral"
          label={t('collections|products', { count: collection.elements?.length })}
          data-testid="collection-products-count"
        />
        <CollectionStatusTag status={collection.collectionStatus} />

        <S.Divider />

        <WppAvatar
          src={creatorAvatarSrc[0]?.signed_url}
          name={collection.createdByName}
          withTooltip
          data-testid="collection-creator-avatar"
        />

        <S.Divider />

        <Flex gap={8}>
          <WppIconHistory color="var(--wpp-grey-color-800)" />
          <S.TypographyGrey type="xs-body" data-testid="collection-update-time">
            {t('common|updated_when', { when: dayjs(collection?.updatedAt!).fromNow() })}
          </S.TypographyGrey>
        </Flex>
      </Flex>
    </S.HeaderWrapper>
  )
}
