import { useCollectionData } from 'hooks/useCollectionData'
import { useCustomDialog } from 'hooks/useCustomDialog'
import { CollectionDetailsHeader } from 'pages/collections/details/components/collectionDetailsHeader/CollectionDetailsHeader'
import { SidebarMenu } from 'pages/collections/details/components/sidebarMenu/SidebarMenu'
import { CollectionProducts } from 'pages/collections/details/tabs/products/CollectionProducts'
import { CollectionDeleteModal } from 'ui-shared/collectionCard/components/CollectionDeleteModal'
import { HashRoute } from 'ui-shared/hashRoute/HashRoute'
import { handleApiErrors } from 'utils/api'

import * as S from 'pages/collections/details/CollectionDetailsPage.styled'

export enum CollectionDetailsTabs {
  PRODUCTS = 'PRODUCTS',
  AVAILABILITY = 'AVAILABILITY',
}

export const CollectionDetailsPage = () => {
  const { collection, isCollectionError, collectionError } = useCollectionData()
  const { open, handleOpen: handleOpenDeleteModal, handleClose: handleCloseDeleteModal } = useCustomDialog()

  if (isCollectionError) {
    return handleApiErrors({ error: collectionError })
  }

  return (
    <S.PageWrapper direction="column" gap={24}>
      <CollectionDetailsHeader handleOpenDeleteModal={handleOpenDeleteModal} />

      <S.PageContent gap={28}>
        <SidebarMenu />

        <HashRoute
          defaultRoute={CollectionDetailsTabs.PRODUCTS}
          routeMap={{
            [CollectionDetailsTabs.PRODUCTS]: () => <CollectionProducts />,
          }}
        />
      </S.PageContent>

      <CollectionDeleteModal
        collectionId={collection?.id!}
        collectionName={collection?.name!}
        open={open}
        handleClose={handleCloseDeleteModal}
        redirectToCollectionsAfterDelete
      />
    </S.PageWrapper>
  )
}
