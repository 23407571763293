import { AxiosResponse } from 'axios'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useCreateCollectionApiWithEffects } from 'api/collections/mutations/useCreateCollectionApi'
import { useUpdateCollectionApiWithEffects } from 'api/collections/mutations/useUpdateCollectionApi'
import { useForm } from 'hooks/form/useForm'
import { useCollectionData } from 'hooks/useCollectionData'
import { useToast } from 'hooks/useToast'
import {
  getCollectionFormValidationSchema,
  getCollectionInitialValues,
  mapCollectionValues,
} from 'pages/collections/common/upsertCollectionFormUtils/formUtils'
import { UpsertCollectionErrorCode } from 'pages/collections/common/upsertCollectionFormUtils/types'
import { CollectionDTO } from 'types/collections/collection'
import { unpackApiError } from 'utils/form'

interface Props {
  isUpdate?: boolean
  onCollectionUpsertSuccess?: (collection: AxiosResponse<CollectionDTO>) => void
  handleCloseSideModal: () => void
}

export const useUpsertCollectionForm = ({
  isUpdate = false,
  onCollectionUpsertSuccess,
  handleCloseSideModal,
}: Props) => {
  const { collection } = useCollectionData()
  const { t } = useTranslation(['errors', 'products', 'collections'])
  const { showToast } = useToast()
  const { mutateAsync: handleCreateCollection } = useCreateCollectionApiWithEffects()
  const { mutateAsync: handleUpdateCollection } = useUpdateCollectionApiWithEffects()

  const form = useForm({
    defaultValues: useMemo(() => getCollectionInitialValues(collection), [collection]),
    validationSchema: getCollectionFormValidationSchema({ t, isUpdate: true }),
  })

  const {
    handleSubmit,
    setError,
    reset,
    watch,
    formState: { isSubmitting, errors },
  } = form

  const onSubmit = handleSubmit(async values => {
    try {
      const collectionData = mapCollectionValues(values)
      let response
      if (isUpdate) {
        response = await handleUpdateCollection({ collectionId: collection.id, collectionData })
      } else {
        response = await handleCreateCollection({ collectionData })
      }

      showToast({ message: t(`collections|${isUpdate ? 'update' : 'create'}_collection.success`), type: 'success' })

      onCollectionUpsertSuccess?.(response)
      handleCloseSideModal()
    } catch (apiError) {
      const { status, code } = unpackApiError(apiError)

      if (status === 403) {
        showToast({ message: t('errors|access_denied.action'), type: 'error' })
      } else if (status === 409) {
        if (code === UpsertCollectionErrorCode.DUPLICATE_NAME_ON_SAME_TENANT)
          setError('name', {
            type: 'duplicate-collection',
            message: t('collections|create_collection.errors.collection_duplicate_on_the_tenant'),
          })
        if (code === UpsertCollectionErrorCode.DUPLICATE_NAME_ON_OTHER_TENANTS)
          setError('name', {
            type: 'duplicate-collection',
            message: t('collections|create_collection.errors.collection_duplicate_on_the_os'),
          })
      } else {
        showToast({ message: t('errors|general'), type: 'error' })
      }
    }
  })

  return {
    form,
    isSubmitting,
    errors,
    onSubmit,
    reset,
    watch,
    setError,
  }
}
