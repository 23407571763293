import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Delays } from 'constants/delays'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { COLLECTION_SHORT_DESCRIPTION_MAX_CHARS } from 'pages/collections/common/upsertCollectionFormUtils/formUtils'
import { FormTextArea } from 'ui-base/form/formTextArea/FormTextArea'
import { FormTextInput } from 'ui-base/form/formTextInput/FormTextInput'

import * as S from 'pages/collections/common/createCollectionSideModal/components/collectionInfoStep/CollectionInfoStep.styled'

interface Props {
  collectionName: string
  validateCollectionName: (collectionName: string) => void
}

export const CollectionInfoStep = ({ collectionName, validateCollectionName }: Props) => {
  const { t } = useTranslation(['collections'])

  const validateCollectionNameDebounced = useDebounceFn((name: string) => {
    validateCollectionName(name)
  }, Delays.InputValidation)

  useEffect(() => {
    !!collectionName.length && validateCollectionNameDebounced(collectionName)
  }, [collectionName, validateCollectionNameDebounced])

  return (
    <S.StepWrapper direction="column" gap={24} data-testid="create-collection-first-step">
      <FormTextInput
        labelConfig={{ text: t('collections|create_collection.fields.name') }}
        name="name"
        required
        data-testid="collection-name-field"
      />
      <FormTextArea
        name="shortDescription"
        labelConfig={{
          text: t('collections|create_collection.fields.shortDescription'),
        }}
        charactersLimit={COLLECTION_SHORT_DESCRIPTION_MAX_CHARS}
        data-testid="collection-short-description-field"
      />
    </S.StepWrapper>
  )
}
