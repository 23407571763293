import { devHubApi } from 'api'
import { CollectionFormDTO } from 'pages/collections/common/upsertCollectionFormUtils/formUtils'
import { CollectionDTO } from 'types/collections/collection'

interface Params {
  collectionData: CollectionFormDTO
}

export const createCollectionApi = ({ collectionData }: Params) =>
  devHubApi.post<CollectionDTO>('/collections', collectionData)
