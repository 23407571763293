import { WppButton, WppIconEdit, WppTypography } from '@platform-ui-kit/components-library-react'
import { AgGridReact } from 'ag-grid-react'
import { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { useCollectionData } from 'hooks/useCollectionData'
import { useCustomDialog } from 'hooks/useCustomDialog'
import { CollectionProductsTable } from 'pages/collections/details/tabs/products/components/collectionProductsTable/CollectionProductsTable'
import { TableEmptyState } from 'pages/collections/details/tabs/products/components/collectionProductsTable/components/tableEmptyState/TableEmptyState'
import { UpdateCollectionSideModal } from 'pages/collections/details/tabs/products/components/updateCollectionSideModal/UpdateCollectionSideModal'
import { Flex } from 'ui-base/flex/Flex'

import * as S from 'pages/collections/details/tabs/products/CollectionProducts.styled'

export const CollectionProducts = () => {
  const { t } = useTranslation(['common'])
  const { collection, isCollectionLoading, isCollectionError } = useCollectionData()
  const {
    open: isUpdateCollectionModalOpen,
    handleOpen: handleOpenUpdateCollectionModal,
    handleClose: handleCloseUpdateCollectionModal,
  } = useCustomDialog()
  const tableRef = useRef<AgGridReact>(null)

  const isCollectionElements = !!collection?.elements.length
  const showEditButton = isCollectionElements && !isCollectionError && !isCollectionLoading

  const handleTableCacheRefresh = useCallback(() => tableRef?.current?.api.refreshInfiniteCache(), [])

  return (
    <S.ProductsCard size="l" data-testid="collection-details-card-content">
      <Flex slot="header" align="center" justify="between" grow={1}>
        <WppTypography type="xl-heading" data-testid="collection-products-card-title">
          {t('common|products')}
        </WppTypography>
        {showEditButton && (
          <WppButton
            variant="secondary"
            size="s"
            onClick={handleOpenUpdateCollectionModal}
            data-testid="edit-collection-button"
          >
            <WppIconEdit slot="icon-start" />
            {t('common|edit')}
          </WppButton>
        )}
      </Flex>

      {!isCollectionLoading && !isCollectionElements ? (
        <TableEmptyState handleOpenUpdateCollectionModal={handleOpenUpdateCollectionModal} />
      ) : (
        <CollectionProductsTable ref={tableRef} />
      )}

      <UpdateCollectionSideModal
        isUpdateCollectionModalOpen={isUpdateCollectionModalOpen}
        handleCloseUpdateCollectionModal={handleCloseUpdateCollectionModal}
        onCollectionUpdateSuccess={handleTableCacheRefresh}
      />
    </S.ProductsCard>
  )
}
