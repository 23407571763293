import { WppActionButton, WppCheckbox, WppPopover, WppTypography } from '@platform-ui-kit/components-library-react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { InvitedUserType } from 'hooks/useGetInvitedUserType'
import {
  CollectionFormProductWithVersionsDTO,
  CollectionFormShortVersionDTO,
  CollectionFormElementValue,
} from 'pages/collections/common/upsertCollectionFormUtils/formUtils'
import { Flex } from 'ui-base/flex/Flex'

import * as S from 'pages/collections/common/createCollectionSideModal/components/productsSelectionStep/components/selectVersionPopover/SelectVersionPopover.styled'

interface Props {
  isProductOwner: boolean
  invitedUserType: InvitedUserType
  productWithVersions: CollectionFormProductWithVersionsDTO
  handleVersionsSelection: (elements: CollectionFormElementValue[]) => void
}

export const SelectVersionPopover = ({ isProductOwner, productWithVersions, handleVersionsSelection }: Props) => {
  const { t } = useTranslation(['common', 'collections'])
  const { versions, id: productId, name: productName, productType, categoryLogoUrl, logoMeta } = productWithVersions
  const [currVersionsState, setCurrVersionsState] = useState<CollectionFormShortVersionDTO[]>([...versions])
  const popoverRef = useRef<HTMLWppPopoverElement>(null)
  const popoverContentRef = useRef<HTMLDivElement>(null)
  const isVersionsSelectionDisabled = !isProductOwner || !versions.length

  const handleClick = useCallback(
    (event: MouseEvent) => {
      const datasetAction = (event?.target as HTMLElement)!.dataset!.action
      if (datasetAction && datasetAction === 'close') {
        popoverRef.current?.closePopover()
      }
      if (!!datasetAction && datasetAction === 'save') {
        const versions = currVersionsState.map(version => ({
          productId,
          productName,
          productType,
          categoryLogoUrl,
          logoMeta,
          shortDescription: version.description,
          versionId: version.id,
          versionName: version.name,
          isSelected: version.isSelected,
        }))
        handleVersionsSelection(versions)
        popoverRef.current?.closePopover()
      }
    },
    [currVersionsState, productName, productId, productType, categoryLogoUrl, logoMeta, handleVersionsSelection],
  )

  // we need this effect to fire events in WppPopover
  useEffect(() => {
    const wrapperNode = popoverContentRef.current

    if (wrapperNode) {
      wrapperNode.addEventListener('click', handleClick, { capture: false })
    }
    return () => {
      if (wrapperNode) {
        wrapperNode.removeEventListener('click', handleClick)
      }
    }
  }, [handleClick])

  return (
    <WppPopover
      config={{
        appendTo: () => document.querySelector('#root')!,
        onShow: () => setCurrVersionsState(versions),
      }}
      shouldCloseOnOutsideClick={() => true}
      ref={popoverRef}
    >
      <S.SelecteVersionButton
        disabled={isVersionsSelectionDisabled}
        slot="trigger-element"
        data-testid="select-version-popover-trigger-button"
      >
        {t(
          `collections|create_collection.products_step.${
            productWithVersions.hasSelectedVersions ? 'edit_selection' : 'select_version'
          }`,
        )}
      </S.SelecteVersionButton>

      <S.ContentWrapper direction="column" ref={popoverContentRef} data-testid="select-version-popover-content">
        <S.Header justify="between" align="center">
          <WppTypography type="m-strong" data-testid="select-version-popover-title">
            {t('collections|create_collection.products_step.select_versions')}
          </WppTypography>
          <WppActionButton variant="secondary" data-action="close" data-testid="select-version-popover-close-button">
            <S.IconClose slot="icon-start" />
          </WppActionButton>
        </S.Header>

        <S.VersionsList direction="column" grow={1} gap={12}>
          {currVersionsState.map(version => (
            <Flex direction="column" key={version.id}>
              <WppCheckbox
                labelConfig={{ text: version.name }}
                checked={version.isSelected}
                onWppChange={({ detail }) => {
                  const updatedState = currVersionsState.map(oldVersion =>
                    oldVersion.id === version.id ? { ...oldVersion, isSelected: detail.checked } : oldVersion,
                  )
                  setCurrVersionsState(updatedState)
                }}
                required
                data-testid="select-version-popover-version-checkbox"
              />
              <S.VersionDescription type="s-body" data-testid="select-version-popover-description">
                {version.description || '-'}
              </S.VersionDescription>
            </Flex>
          ))}
        </S.VersionsList>

        <Flex justify="end">
          <WppActionButton variant="secondary" data-action="close" data-testid="select-version-popover-cancel-button">
            {t('common|cancel')}
          </WppActionButton>
          <WppActionButton data-action="save" data-testid="select-version-popover-save-button">
            {t('common|save')}
          </WppActionButton>
        </Flex>
      </S.ContentWrapper>
    </WppPopover>
  )
}
