import { styled } from '@mui/material/styles'
import { WppListItem } from '@platform-ui-kit/components-library-react'

import { Flex } from 'ui-base/flex/Flex'

export const MenuItem = styled(WppListItem)`
  width: 100%;
  &::part(item) {
    width: 100%;
  }
`

export const SidebarMenu = styled(Flex)`
  flex-flow: column wrap;
  flex-shrink: 0;
  width: 204px;
`
