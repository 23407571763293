import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { matchPath, useParams } from 'react-router-dom'

import { useAppData } from 'hooks/useAppData'
import { RoutesManager } from 'utils/routesManager'

const ProductBreadcrumbsItems = {
  ROOT: RoutesManager.myProducts.root.pattern,
  DASHBOARD_PAGE: RoutesManager.products.root.pattern,
  DETAILS_PAGE: RoutesManager.products.details.pattern,
  VERSIONS_LAUNCH_PAGE: RoutesManager.products.versions.launch.pattern,
  PROFILES_UPDATE_PAGE: RoutesManager.products.profiles.update.pattern,
  NATIVE_VERSIONS_UPDATE_PAGE: RoutesManager.products.versions.update.pattern,
  NO_CODE_VERSIONS_UPDATE_PAGE: RoutesManager.products.versions.update.pattern + 'noCodeApp',
  COLLABORATION_PAGE: RoutesManager.products.collaboration.pattern,
  COLLECTIONS_LIST_PAGE: RoutesManager.collections.root.pattern,
  COLLECTION_DETAILS_PAGE: RoutesManager.collections.details.pattern,
}

const pagesWithBreadcrumbs = [
  // Be careful with pattern specificity. Less specific patterns should placed below.
  ProductBreadcrumbsItems.DETAILS_PAGE,
  ProductBreadcrumbsItems.VERSIONS_LAUNCH_PAGE,
  ProductBreadcrumbsItems.NATIVE_VERSIONS_UPDATE_PAGE,
  ProductBreadcrumbsItems.PROFILES_UPDATE_PAGE,
  ProductBreadcrumbsItems.NO_CODE_VERSIONS_UPDATE_PAGE,
  ProductBreadcrumbsItems.COLLABORATION_PAGE,
  ProductBreadcrumbsItems.DASHBOARD_PAGE,
  ProductBreadcrumbsItems.COLLECTIONS_LIST_PAGE,
  ProductBreadcrumbsItems.COLLECTION_DETAILS_PAGE,
] as const

const getMatchedUrl = (pathname: string, isNoCodeApp: boolean): string => {
  const url = pagesWithBreadcrumbs.find(pattern => matchPath(pattern, pathname))
  if (isNoCodeApp && url === ProductBreadcrumbsItems.NATIVE_VERSIONS_UPDATE_PAGE) {
    return ProductBreadcrumbsItems.NO_CODE_VERSIONS_UPDATE_PAGE
  }
  return url!
}

export const useBreadcrumbsConfig = (pathname: string, breadcrumbLabel?: string) => {
  const { t } = useTranslation(['products'])
  const { product, isNoCodeApp } = useAppData()
  const { productId, versionId, profileId, collectionId } = useParams<{
    productId: string
    versionId: string
    profileId: string
    collectionId: string
  }>()
  const matchedUrl = getMatchedUrl(pathname, isNoCodeApp)

  const { root, detailsPage, dashboardPage, profilesUpdatePage, nativeVersionUpdatePage, noCodeVersionUpdatePage } =
    useMemo(
      () => ({
        root: { path: RoutesManager.myProducts.root.getURL(), label: t('products|breadcrumbs.my_products') },
        dashboardPage: { path: RoutesManager.products.root.getURL({ productId: productId! }), label: product?.name! },
        detailsPage: {
          path: RoutesManager.products.details.getURL({ productId: productId! }),
          label: t('products|breadcrumbs.details'),
        },
        profilesUpdatePage: {
          path: RoutesManager.products.profiles.update.getURL({ productId: productId!, profileId: profileId! }),
          label: breadcrumbLabel!,
        },
        nativeVersionUpdatePage: {
          path: RoutesManager.products.versions.update.getURL({ productId: productId!, versionId: versionId! }),
          label: breadcrumbLabel!,
        },
        noCodeVersionUpdatePage: {
          path: RoutesManager.products.versions.update.getURL({ productId: productId!, versionId: versionId! }),
          label: t('products|breadcrumbs.product_config'),
        },
        collectionsListPage: {
          path: RoutesManager.collections.root.getURL(),
          label: t('products|breadcrumbs.product_config'),
        },
        collectionDetailsPage: {
          path: RoutesManager.collections.details.getURL({ collectionId: collectionId! }),
          label: t('products|breadcrumbs.product_config'),
        },
      }),
      [product, productId, profileId, versionId, collectionId, breadcrumbLabel, t],
    )

  const breadcrumbsConfig = useMemo(
    () => ({
      [ProductBreadcrumbsItems.DASHBOARD_PAGE]: [root, dashboardPage],
      [ProductBreadcrumbsItems.DETAILS_PAGE]: [root, dashboardPage, detailsPage],
      [ProductBreadcrumbsItems.PROFILES_UPDATE_PAGE]: [root, dashboardPage, profilesUpdatePage],
      [ProductBreadcrumbsItems.NATIVE_VERSIONS_UPDATE_PAGE]: [root, dashboardPage, nativeVersionUpdatePage],
      [ProductBreadcrumbsItems.NO_CODE_VERSIONS_UPDATE_PAGE]: [root, dashboardPage, noCodeVersionUpdatePage],
    }),
    [root, dashboardPage, detailsPage, profilesUpdatePage, nativeVersionUpdatePage, noCodeVersionUpdatePage],
  )

  const breadcrumbsItems = breadcrumbsConfig[matchedUrl!]
  const isDashboardPage = matchedUrl === ProductBreadcrumbsItems.DASHBOARD_PAGE
  const isCollaborationPage = matchedUrl === ProductBreadcrumbsItems.COLLABORATION_PAGE
  const isVersionLaunchPage = matchedUrl === ProductBreadcrumbsItems.VERSIONS_LAUNCH_PAGE
  const isCollectionsListPage = matchedUrl === ProductBreadcrumbsItems.COLLECTIONS_LIST_PAGE
  const isCollectionDetailsPage = matchedUrl === ProductBreadcrumbsItems.COLLECTION_DETAILS_PAGE
  const isNativeVersionUpdatePage = matchedUrl === ProductBreadcrumbsItems.NATIVE_VERSIONS_UPDATE_PAGE
  const isNoCodeVersionUpdatePage = matchedUrl === ProductBreadcrumbsItems.NO_CODE_VERSIONS_UPDATE_PAGE

  return {
    breadcrumbsItems,
    isDashboardPage,
    isCollaborationPage,
    isVersionLaunchPage,
    isCollectionsListPage,
    isCollectionDetailsPage,
    isNativeVersionUpdatePage,
    isNoCodeVersionUpdatePage,
  }
}
