import { devHubApi } from 'api'
import { CollectionFormDTO } from 'pages/collections/common/upsertCollectionFormUtils/formUtils'
import { CollectionDTO } from 'types/collections/collection'

interface Params {
  collectionId: string
  collectionData: CollectionFormDTO
}

export const updateCollectionApi = ({ collectionId, collectionData }: Params) =>
  devHubApi.put<CollectionDTO>(`/collections/${collectionId}`, collectionData)
