import { WppIconTrash, WppSkeleton } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { useCollectionData } from 'hooks/useCollectionData'
import { CollectionHeader } from 'pages/collections/common/collectionHeader/CollectionHeader'
import { Flex } from 'ui-base/flex/Flex'

import * as S from 'pages/collections/details/components/collectionDetailsHeader/CollectionDetailsHeader.styled'

interface Props {
  handleOpenDeleteModal: () => void
}

export const CollectionDetailsHeader = ({ handleOpenDeleteModal }: Props) => {
  const { t } = useTranslation(['collections'])
  const { isCollectionLoading } = useCollectionData()

  if (isCollectionLoading) {
    return (
      <Flex gap={16} direction="column" data-testid="collection-details-header-skeleton">
        <WppSkeleton height={22} width={151} />
        <Flex justify="between" align="center">
          <WppSkeleton height={40} width="30%" />
          <WppSkeleton height={32} width={151} />
        </Flex>
        <WppSkeleton height={24} width={350} />
      </Flex>
    )
  }

  return (
    <CollectionHeader
      renderRight={
        <S.DeleteCollectionButton
          variant="secondary"
          onClick={handleOpenDeleteModal}
          data-testid="delete-collection-button"
        >
          <WppIconTrash slot="icon-start" />
          {t('collections|delete_collection')}
        </S.DeleteCollectionButton>
      }
    />
  )
}
